import React from 'react'
import { Link } from 'gatsby'
import { TweenMax } from 'gsap'
import { connect } from 'react-redux'
import { useTranslation } from "react-i18next";
import { TranslationConstants } from '../../Constants'
import style from './style.module.scss'

const FeaturedInstructions = (props) => {
    const { t } = useTranslation();

    const onMoustEnter = () => {
        TweenMax.to("#learn-arrow", 0.2, { x: 3 })
    }
    const onMoustLeave = () => {
        TweenMax.to("#learn-arrow", 0.2, { x: -3 })
    }
    let { name: productName, photos = [] } = props.product

    let { ready_in, directions, ingredients, serves, photo, name } = props.recipe

    return (
        <div className={style.container}>

            <div className={style.instructionsWrapper}>
                <h1>{t(TranslationConstants.cookingInstructions)}</h1>
                <span className={style.serving}>
                    <h1>{t(TranslationConstants.readyIn)}&nbsp;</h1><h2>{ready_in}</h2>
                </span>
                <span className={style.serving}>
                    <h1>{t(TranslationConstants.serves)}&nbsp;</h1><h2>{serves}</h2>
                </span>
                <div className={style.ingredients}>
                    <h1>{t(TranslationConstants.ingredients)}</h1>
                    <p>{ingredients}</p>
                </div>
                <div className={style.ingredients}>
                    <h1>{t(TranslationConstants.directions)}</h1>
                    <p>{directions}</p>
                </div>
            </div>
            <div className={style.productWrapper}>
                <h1>{t(TranslationConstants.featuredProduct)}</h1>
                <div className={style.imageWrapper}>
                    <div className={style.imageContent}>
                        <img src={photos.length > 0 ? photos[0].location : null} />
                        <h3>{productName}</h3>
                    </div>
                </div>
            </div>
        </div>

    )
}


export default connect((state, props) => {
    let newIndex = null;

    let foundProduct = state.products.reduce((found, current, index) => {
        let value = current.id === props.recipe.products_id ? current : found
        newIndex = value != found ? index : newIndex
        return value
    }, {})

    return {
        index: newIndex,
        product: foundProduct
    }
})(FeaturedInstructions)