import React from 'react'
import Layout from '../components/layout'
import { QueryParams } from '../components'
import Featured from '../components/FeaturedRecipe'


const SingleRecipe = (props) => {
    return (
        <Layout>
            <Featured {...props} />
        </Layout>
    )
}

export default QueryParams(SingleRecipe)

