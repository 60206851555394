import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import style from './style.module.scss'
import PageHeader from '../PageHeader'
import Instructions from '../FeaturedInstructions'
import Scrollable from '../Scrollable'
import ViewMore from '../ViewMoreProducts'
import Footer from '../Footer'
import DashLines from '../../images/blue_dash_lines.png'
import Zoom from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';

const FeaturedRecipe = (props) => {

    const { recipe = {} } = props;
    let { name = 'N/A', details = '', photos = [] } = recipe
    let { location = '' } = photos[0] || {}

    return (
        <div className={style.featuredContainer}>
            <Scrollable>
                {
                    !props.recipe ? <h1 style={{ minHeight: '100vh' }}>Loading...</h1> :
                        <>
                            <div className={style.recipeHeader}>
                                <Zoom>
                                    <h1 className={style.recipeHeaderText}>{name}</h1>
                                </Zoom>
                                <Slide right>
                                    <img className={style.headerRecipeImage} src={location} />
                                </Slide>
                            </div>
                            <Instructions {...props} />
                        </>
                }

                {/* <ViewMore color={blue} header={'VIEW MORE RECIPES'} /> */}
                <Footer />
            </Scrollable>
        </div>
    )
}

export default connect((state, { search }) => {
    return ({
        recipeLoading: state.getRecipesLoading,
        recipe: state.recipes[+search.id]
    })
})(FeaturedRecipe)