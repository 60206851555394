import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import style from './style.module.scss'
import ProductCell from '../ProductCell'

const ViewMoreProducts = ({ background, color, theme, header, products, getProductsLoading, isAnimating }) => {

    return (
        <div className={style.row} style={{ background: background ? `${background}` : null, color: color ? `${color}` : null }}>
            <h1 className={style.viewMoreHeader}>{header}</h1>
            <div className={style.cellRow}>
                {
                    !getProductsLoading ? products.map((item, key) => <ProductCell disabled={isAnimating} circleClass={style.productCircle} theme={'blue'} key={key} index={item.index} data={item} />) : null
                }
            </div>
        </div>
    )
}

ViewMoreProducts.propTypes = {
    background: PropTypes.string,
    color: PropTypes.string,
    header: PropTypes.string,
    theme: PropTypes.bool
}

ViewMoreProducts.defaultProps = {
    background: null,
    color: null,
    header: null,
    theme: false
}

export default connect((state, props) => {
    let products = state.products.reduce((totalProducts, currentProduct, key) => {
        if (props.index == key || totalProducts.length >= 3) {
            return totalProducts
        } else {
            totalProducts.push({ ...currentProduct, index: key })
            return totalProducts
        }
    }, [])
    return {
        getProductsLoading: state.getProductsLoading,
        products: products
    }

})(ViewMoreProducts)